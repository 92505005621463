import { gsap, Quad } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { ScrollScene } from "scrollscene";
import styled from "styled-components";
import Anchor from "~/components/Anchor";
import Block, { BlockContent } from "~/components/Block";
import MediaFull from "~/components/MediaFull";
import { Desktop, Mobile } from "~/components/ShowcaseB";
import Title from "~/components/Title";
import { getCommonSliceProps, resolveMedia } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";
import { ThemeContext } from "~/lib/ThemeContext";
import { paragraph } from "~/styles/mixins";
import { parseFontStyle, parseFontWeight, smallerThan } from "~/styles/theme";

gsap.registerPlugin(ScrollTrigger);

const vh = (coef) => window.innerHeight * (coef / 100);
const vw = (coef) => window.innerWidth * (coef / 100);

const Text = styled.div<any>`
  color: ${(p) => p.color || p.theme.colors.dark};
  font-weight: ${(p) => p.fontWeight || "400"};
  font-family: "AIAIAI${(p) => p.fontStyle || ""}";
  p {
    ${paragraph};
    /* margin: ${(p) => p.theme.sizes.vSpacing}rem 0; */
    padding-bottom: ${(p) => p.theme.sizes.vSpacing}rem;
    /*width: 80%;*/

    &:first-child {
      padding-top: ${(p) => p.theme.sizes.vSpacing}rem;
    }

    ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
      width: 80%;
    }

    ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
      width: auto;
    }
  }
`;

function TitleAndText(props: {
  spacingTop?: number;
  spacingBottom?: number;
  backgroundColor?: string;
  color?: string;
  animateIn?: false;
  highlightColor?: string;
  highlightOutline?: false;
  anchorText?: any;
  anchorPreText?: any;
  anchorId?: any;
  titleType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  titleColor?: string;
  line1?: any; // rich text
  line2?: any; // rich text
  line3?: any; // rich text
  titleFontStyle?: string;
  titleFontWeight?: string;
  size?: number;
  text: any; // normal or rich text
  textSize?: string;
  fontWeight?: string;
  fontStyle?: string;
}) {
  const currentTheme = useContext(ThemeContext);

  const {
    spacingTop = 0,
    spacingBottom = 6,

    backgroundColor = currentTheme.background,
    color = currentTheme.foreground,
    animateIn = false,

    highlightColor = currentTheme.highlight,
    highlightOutline = false,
    anchorText,
    anchorPreText,
    anchorId,
    titleType = "h3",
    titleColor = currentTheme.foreground,
    line1,
    line2,
    line3,
    titleFontStyle = parseFontStyle(null, "Normal"),
    titleFontWeight = parseFontWeight(null, "Normal"),
    size = 0.5,
    text,
    textSize = "Normal",
    fontWeight = "400",
    fontStyle = "",
  } = props;

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent>
        {anchorText && (
          <Anchor id={anchorId} pre={anchorPreText} color={color}>
            {anchorText}
          </Anchor>
        )}
        <Title
          highlightColor={highlightColor}
          highlightOutline={highlightOutline}
          type={titleType}
          line1={line1}
          line2={line2}
          line3={line3}
          size={size}
          color={titleColor}
          fontWeight={titleFontWeight}
          fontStyle={titleFontStyle}
          animateIn={animateIn}
        />
        {text && (
          <Text fontWeight={fontWeight} fontStyle={fontStyle} color={color}>
            {text}
          </Text>
        )}
      </BlockContent>
    </Block>
  );
}

function VideoScroller(props) {
  const currentTheme = useContext(ThemeContext);

  const {
    media,
    backgroundColor = currentTheme.background,
    foregroundColor = currentTheme.foreground,
    highlightColor = currentTheme.highlight,
    config = {},
  } = props;

  const [url, type, width, height = null, alt = null, poster = null, muted = true] =
    media;

  if (type !== "video") {
    return null;
  }

  const [containerRef, inView, entry] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "200px 0px",
  });

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const switchRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (
      !videoRef.current ||
      (config.type === "pinned_scrub_with_switch" && !switchRef.current) ||
      !inView ||
      loading ||
      loaded
    ) {
      return;
    }
    setLoading(true);
    const onLoadedData = () => {
      setLoaded(true);
      setLoading(false);
      const { duration } = videoRef.current;
      const timelineVideo = gsap.timeline({
        paused: true,
        smoothChildTiming: true,
      });
      timelineVideo.to(videoRef.current, {
        scrollTrigger: {
          id: "video",
          trigger: videoRef.current,
          // start: () => `${vh(43.5)} bottom`,
          start: () => `${vh(91)} bottom`,
          end: "bottom top",
          markers: Boolean(config.debug?.indicators),
          scrub: 1,
          pin: true,
        },
        duration: 1,
        currentTime: duration,
        ease: Quad.easeOut,
        overwrite: true,
        pause: true,
      });
      // timeline;
      // entry.target.getBoundingClientRect().height
      const scrollSceneVideo = new ScrollScene({
        triggerElement: entry.target,
        duration: "100%", // { 0: '50%', 768: '100%' },
        // breakpoints:
        // offset: '180%', // TODO
        gsap: {
          // delay: 0,
          speed: 0.5,
          timeline: timelineVideo,
        },
      });
      if (config.debug?.indicators) {
        scrollSceneVideo.Scene.addIndicators({
          name: "scene-video",
          colorEnd: "#FFFFFF",
        });
      }
      if (config.type === "pinned_scrub_with_switch") {
        const timelineSwitch = gsap.timeline().set(switchRef.current, {
          scrollTrigger: {
            id: "switch",
            trigger: switchRef.current,
            start: "center center",
            // start: () => `${vh(90)} center`,
            end: "center center",
            markers: Boolean(config.debug?.indicators),
            pin: true,
            onEnter: () => {
              switchRef.current.src =
                "https://dtfa4u45jidr9.cloudfront.net/wireless-plus/switch-low-latency@3x.png";
            },
            onEnterBack: () => {
              switchRef.current.src =
                "https://dtfa4u45jidr9.cloudfront.net/wireless-plus/switch-bluetooth@3x.png";
            },
          },
        });
      }
      // entry.target.getBoundingClientRect().height
      /* const scrollSceneSwitch = new ScrollScene({
        triggerElement: entry.target,
        duration: '100%', // { 0: '50%', 768: '100%' },
        // breakpoints:
        offset: '180%', // TODO
        gsap: {
          //delay: 0,
          //speed: 1,
          timeline: timelineSwitch
        }
      });
      scrollSceneSwitch.Scene.addIndicators({
        name: 'scene-switch',
        colorEnd: '#FFFFFF'
      }); */

      /* gsap.set(switchRef.current, {
        scrollTrigger: {
          id: 'switch',
          trigger: switchRef.current,
          start: 'center center',
          //start: () => `${vh(90)} center`,
          end: 'center center',
          markers: true,
          pin: true,
          scrub: true,
          //pinType: 'transform',
          //pinnedContainer: switchRef.current.parentElement,
          onEnter: () => {
            switchRef.current.src =
              'https://dtfa4u45jidr9.cloudfront.net/wireless-plus/switch-low-latency@3x.png';
          },
          onEnterBack: () => {
            switchRef.current.src =
              'https://dtfa4u45jidr9.cloudfront.net/wireless-plus/switch-bluetooth@3x.png';
          }
        }
      }); */
    };
    videoRef.current.addEventListener("loadeddata", onLoadedData);
    videoRef.current.load(); // Needed?
    return () => {
      videoRef.current?.removeEventListener("loadeddata", onLoadedData);
    };
  }, [inView]);

  return (
    <div ref={containerRef}>
      {config.type === "pinned_scrub_with_switch" && (
        <div
          /* style={{
            zIndex: 2,
            position: 'absolute',
            bottom: '6vw',
            right: '4vw',
            left: '57.5vw'
          }} */
          style={{
            zIndex: 2,
            position: "absolute",
            bottom: "6vw",
            right: "4vw",
            left: "50vw",
          }}
        >
          {/*
          <h3 style={{marginBottom: '0.5vw', lineHeight: '1.1', fontSize: 'large'}}>
            <div style={{ color: highlightColor }}>LATENCY-FREE 2.4GHz</div>
            <div style={{ color: '#fff' }}>WIRELESS CONNECTION</div>
          </h3>
          <p style={{ color: foregroundColor, fontSize: '1.1vh' }}>
            Lossless and robust 16ms latency connection for high quality wireless
            audio designed for enhanced freedom during live performance and music
            making.
          </p>
          */}
          <div style={{ display: "grid", gridTemplateColumns: "1fr 10fr" }}>
            <div style={{ color: "#fff", textAlign: "center" }}>
              <img
                ref={switchRef}
                src="/static/assets/icons/wireless-plus-switch-bluetooth@3x.png"
                alt="Wireless+ connection switch"
                style={{ width: "40px" }}
              />
            </div>
            <TitleAndText
              backgroundColor="transparent"
              anchorId="multi-functional"
              anchorText="Multi-functional"
              titleType="h6"
              titleColor="#fff"
              line1={
                <p>
                  2.4GHz <strong>LATENCY-</strong>
                </p>
              }
              line2={
                <p>
                  <strong>FREE</strong>, LOSSLESS,
                </p>
              }
              line3={<p>WIRELESS AUDIO</p>}
              text={
                <p>
                  Lossless and robust 16ms latency connection for high quality wireless
                  audio designed for freedom during live performance and music making.
                  <br />
                  <br />
                  2.4GHz Latency Free Wireless Transmission: Lossless
                  <br />
                  <br />
                  Latency: 16ms, stable
                </p>
              }
            />
          </div>
        </div>
      )}
      <video
        ref={videoRef}
        muted
        playsInline
        autoPlay={false}
        loop
        disablePictureInPicture
        style={{ width: "100%" }}
      >
        <source src={url} />
      </video>
    </div>
  );
}

function MediaFullSlice(props) {
  const {
    spacingTop = 0,
    spacingBottom = 6,

    color,
    backgroundColor,
    animateIn = false,
    fullWidth = false,
    media,
    mediaMobile,
    lazy,
    loop,
    href,
    hrefAs,
    override,
  } = props;

  const clickable = true;

  if (override?.type === "video_scroller") {
    return (
      <Block
        spacingTop={spacingTop}
        spacingBottom={spacingBottom}
        /* noSpacingLeft={fullWidth}
        noSpacingRight={fullWidth} */
        noSpacingLeft
        noSpacingRight
        backgroundColor={backgroundColor}
        color={color}
      >
        <BlockContent
          /* noSpacingLeft={fullWidth}
          noSpacingRight={fullWidth} */
          noSpacingLeft
          noSpacingRight
        >
          {!mediaMobile ? (
            <VideoScroller config={override.config} media={media} />
          ) : (
            <>
              <Desktop>
                <VideoScroller config={override.config} media={media} />
              </Desktop>
              <Mobile>
                <VideoScroller config={override.config} media={mediaMobile} />
              </Mobile>
            </>
          )}
        </BlockContent>
      </Block>
    );
  }

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      noSpacingLeft={fullWidth}
      noSpacingRight={fullWidth}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent noSpacingLeft={fullWidth} noSpacingRight={fullWidth}>
        {!mediaMobile ? (
          <MediaFull
            media={media}
            lazy={lazy}
            loop={loop}
            noPadding
            href={href}
            hrefAs={hrefAs}
            clickable={clickable}
          />
        ) : (
          <>
            <Desktop>
              <MediaFull
                media={media}
                lazy={lazy}
                loop={loop}
                noPadding
                href={href}
                hrefAs={hrefAs}
                clickable={clickable}
              />
            </Desktop>
            <Mobile>
              <MediaFull
                media={mediaMobile}
                lazy={lazy}
                loop={loop}
                noPadding
                href={href}
                hrefAs={hrefAs}
                clickable={clickable}
              />
            </Mobile>
          </>
        )}
      </BlockContent>
    </Block>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;

  const props = {
    ...getCommonSliceProps(slice),
    media: resolveMedia(primary, "16:9", "xlarge"),
    mediaMobile: resolveMedia(primary, "4:3", "large", true),
    fullWidth: primary.full_width || false,
    lazy: primary.lazy_load === "Yes",
    loop: primary.video_loop,
    href: primary.internal_href || undefined,
    hrefAs: primary.internal_hrefas || undefined,
    override: JSON.parse(
      primary.override
      // ?? '{"type":"video_scroller","config":{"type":"simple_pinned_scrub","debug":{"indicators":false}}}'
    ),
  };
  return props;
}

export default connectSlice(mapSliceToProps)(MediaFullSlice);
